<template>
  <div>
    <banner
      :center="true"
      title=""
      content=""
      background="https://socialize.zkyunteng.cn/fronts/website/talent-plan.jpg"
    ></banner>
    <!-- <banner
      :center="true"
      title="公司产品"
      content="云钜科技推行“从客户中来，到客户中去”的产品研发策略，与行业客户紧密合作，共同打造应急消防领域的标杆公司产品"
      background="https://socialize.zkyunteng.cn/fronts/website/talent-plan.jpg"
    ></banner> -->

    <transition name="page" mode="out-in" enter-active-class="animate__animated animate__fadeIn">
      <router-view />
    </transition>
  </div>
</template>

<script>
import Banner from '@/components/Banner'

export default {
  components: {
    Banner
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.talent-plan_content {
  @include size(100%);
  padding: 71px 120px 50px;
  @include flex-center(space-between);
  align-items: flex-start;
  box-sizing: border-box;

  padding-bottom: 100px;

  &_menu {
    @include size(262px, 334px);
    @include flex-center(flex-start, column);
    border-right: 1px solid #d8d8d8;
    margin-right: 60px;
    flex-shrink: 0;

    li {
      @include size(100%, 57px);
      font-size: 18px;
      color: rgba($color: #000000, $alpha: 0.6);
      line-height: 57px;
      position: relative;
      text-indent: 60px;
      cursor: pointer;

      &.active {
        color: #005eef;
        &::after {
          content: '';
          @include size(2px, 100%);
          background-color: #005eef;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
  }

  &_lists {
    flex: 1;
    border-top: 1px solid #d8d8d8;
    @include flex-center(center, column);
    flex-shrink: 0;
    transition: all 0.2s ease;

    li {
      @include flex-center(center);
      padding-bottom: 24px;
      margin-top: 24px;
      border-bottom: 1px solid #d8d8d8;
      overflow: hidden;
      cursor: pointer;
      div:first-child {
        flex-shrink: 0;
        @include size(292px, 174px);
        overflow: hidden;
        margin-right: 24px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        transition: transform 0.2s ease;
      }

      div:last-child {
        h3 {
          font-size: 28px;
          color: rgba($color: #000000, $alpha: 0.9);
        }
        p {
          font-size: 16px;
          color: rgba($color: #000000, $alpha: 0.8);
          line-height: 1.5;
          margin-top: 11px;
        }
      }

      &:hover {
        div:first-child {
          transform: scale(1.05);
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 30px 20px;

    &_menu {
      display: none;
    }

    &_lists {
      flex-wrap: wrap;

      li {
        padding-bottom: 12px;
        margin-top: 12px;
        flex-wrap: wrap;
        div:first-child {
          @include size(335px, 121px);
          margin-right: 0;
        }

        div:last-child {
          h3 {
            font-size: 14px;
            margin-top: 12px;
          }
          p {
            font-size: 12px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
}
</style>
