function getImg(index) {
  // return `https://socialize.zkyunteng.cn/fronts/website/media/media-${index}.png`
  return require(`@/assets/images/media/media-${index}.png`)
}

export default [
  {
    title: '基于3D可视化平台基础底座，构建产业地图模块',
    time: '2020-04-15',
    logo: getImg(1),
    id: 1,
    from: '云钜科技有限公司',
    data: [
      {
        type: 1,
        content:
          '基于3D可视化平台基础底座，构建产业地图模块，通过三维技术实现对空港物流产业当前建设现状数据可视化，实现产业数据与地图的融合呈现。通过对产业地图的理解和项目的需求，对产业功能区划、产业画像、园区画像、企业画像、重点工程等功能进行细化和实现。'
      },
      // {
      //   type: 1,
      //   content:
      //     '珠海长炼石化设备有限公司是深圳埃森新能源集团所属全资公司，位于珠海高栏港经济石化区北侧，建于2003年8月。主要生产装置有120个/年预先装置，100催化剂/年催化重整装置，40台/年芳烃抽提精馏装置，80台/年后分馏以及20台/年苯和甲苯加氢装置等相关的公用工程和储运系统。1月14日13时40分，珠海长炼石化设备有限公司重整与加氢装置预加氢单元发生闪爆。事件发生后，珠海市、区应急管理、消防、生态环境、公安、安监等部门赶赴现场，组织全力扑救。珠海市、高栏港区及时启动了应急预案，各项应急处置工作有序进行。处理工作开展迅速，明火被及时扑灭，灾情得到了迅速有效的控制。'
      // },
      // {
      //   type: 1,
      //   content: '复盘过程中，通过现场学习了解，云钜科技公司进一步提炼了经验做法，深入剖析作战环节存在的问题，分析问题成因，提出了改进措施。'
      // }
    ]
  },
  {
    title: '大数据信息化将是农业现时代的制高点',
    time: '2020-08-26',
    logo: getImg(2),
    id: 5,
    from: '云钜科技有限公司',
    data: [
      {
        type: 1,
        content: '国务院发布《促进大数据发展行动纲要》提出：“发展数字乡村大数据，构建面向农业农村的综合信息服务体系，为农民生产生活提供综合、高效、便捷的信息服务，缩小城乡数字鸿沟，促进城乡发展一体化。加强农业农村经济大数据建设，完善村、县相关数据采集、传输、共享基础设施，建立农业农村数据采集、运算、应用、服务体系，强化农村生态环境治理，增强乡村社会治理能力。统筹国内国际农业数据资源，强化农业资源要素数据的集聚利用，提升预测预警能力。整合构建国家涉农大数据中心，推进各地区、各行业、各领域涉农数据资源的共享开放，加强数据资源发掘运用。加快农业大数据关键技术研发，加大示范力度，提升生产智能化、经营网络化、管理高效化、服务便捷化能力和水平。”明确了农业大数据发展的重点和方向'
      }
    ]
  },
  // {
  //   title: '云钜科技打造的物联网装备管理系统获消防部门的高度肯定',
  //   time: '2020-06-18',
  //   logo: getImg(12),
  //   id: 6,
  //   data: [
  //     {
  //       type: 1,
  //       content: '近期，由云钜科技打造的物联网装备管理系统相继在中山高新区消防大队、中山市公安消防局通过了项目招标，采购单位对云钜科技的产品和技术实力给予了充分的肯定和高度的赞扬。'
  //     },
  //     {
  //       type: 1,
  //       content: '该系统是应急管理部着力推动的核心业务系统，其推广使用将显著提升消防部门的仓库管理、指挥调度和资源调度能力，具有防范化解重大安全风险能力，并进一步增强智慧消防管理信息化水平。'
  //     },
  //     {
  //       type: 1,
  //       content: '该系统的顺利中标不仅是公司为消防部门交出的一份满意答卷，也体现了公司多年来在应急消防救援行业的扎实积累和深耕细作。'
  //     },
  //     {
  //       type: 1,
  //       content:
  //         '公司作为早期从事应急消防救援领域的公司之一，推动了国家智慧消防体系的建设和推广。 随着社会对公共安全的日益重视，公司紧紧抓住了智慧消防与应急救援行业快速发展的机遇，利用自身对行业理解、专业深度、技术储备、市场资源等方面的壁垒优势，牢牢占据行业领先地位。未来公司将继续扎实打造应急消防救援领域核心竞争力，强化品牌影响力，持续加强市场拓展能力。'
  //     }
  //   ]
  // },
  // {
  //   title: '云钜科技连续中标多个项目',
  //   time: '2021-01-10',
  //   logo: getImg(13),
  //   id: 7,
  //   data: [
  //     {
  //       type: 1,
  //       content:
  //         '新年之际，云钜科技在智慧消防的推广和建设上颇有斩获，包括江门市消防救援支队消防装备实战管理及战勤保障平台设备采购项目、珠海市横琴新区消防救援大队三维建模项目、中山市公安消防局装备物联网等多个项目陆续中标。公司基于自身在智慧消防领域的技术优势，通过物联网、云计算、大数据、人工智能等现代信息技术赋能传统消防产业，并进一步推广创新的消防安全服务模式。'
  //     },
  //     {
  //       type: 1,
  //       content: '未来，公司将继续沿着发展安全科技的道路不断前进，持续聚焦智慧消防产业，赋能消防应急救援事业新发展。'
  //     }
  //   ]
  // }
]
