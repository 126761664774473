const getImg = (index, isJpeg = false) => require(`@/assets/images/articles/article${index}-1${isJpeg ? '.jpg' : '.png'}`)

const TYPE_STATE = {
  NEW: 0,
  FIRE: 1,
  SOCIETY: 2
}

export const menuList = [
  { title: '数据治理与数据资产管理', name: 'manage' },
  { title: '企业数据中台', name: 'central' },
  { title: '数据运营监控', name: 'control' },
  { title: '区域农业大数据', name: 'screen' },
  { title: '智慧冷链', name: 'logistics' },
  { title: '智慧农业', name: 'farmManage' },
  { title: '数字化工厂', name: 'factory' }
]

export const listsData = [
  // {
  //   id: 0,
  //   type: [TYPE_STATE.NEW, TYPE_STATE.FIRE, 'manage'],
  //   title: '装备实战管理平台公司产品',
  //   avatar: getImg(0, true),
  //   content:
  //     '某消防救援支队消防装备管理一直以来处于手工管理的状态，装备底数不清，指挥调度困难。为解决上述问题，该支队引进部署云钜科技装备实战管理平台，为重要装备绑定电子标签，实现装备身份数字化，并通过装备实战管理平台实现了“查装备、管装备、用装备和评装备”，解放了消防员双手，大幅度提升了装备管理的效率和实战水平。'
  // },
  // {
  //   id: 1,
  //   type: [TYPE_STATE.NEW, TYPE_STATE.SOCIETY],
  //   title: '社会单位数字化应急预案平台公司产品',
  //   avatar: getImg(1),
  //   content:
  //     '某大型火力发电厂在消防安全方面非常重视，厂区配有专职消防队和大量的灭火救援装备，也制定了完善的事故处理预案，这些预案停留在文本阶段，厂区安全人员很难基于文本预案进行演练，同时文本预案冗长，无法给到厂区所有工作人员学习，限制了安全知识的传播。该电厂在调研后，开通了云钜科技社会化数字预案管理平台账号，一方面将原来的文本预案上传到平台存档；另一方面在平台内加载厂区三维模型，在三维模型上将原来文本预案中的处置指引转化为三维动态效果，并基于三维预案电子沙盘进行处置演练，提升了演练效果。三维预案可通过H5页面分享给厂区所有工作人员，真正实现了全员安全教育。电厂的消防基础数据同步到所属的消防大队，实现了数据联动，为后续救援提供有力保障，电厂安全更有保障'
  // },
  // {
  //   id: 2,
  //   type: [TYPE_STATE.NEW, TYPE_STATE.FIRE],
  //   title: '119互联网辅助接处警平台公司产品',
  //   avatar: getImg(3),
  //   content:
  //     '传统119接警系统存在定位不准和沟通不形象两大难题，通过运营商基站定位获得的地址与真实地址往往有一定的差距（几十米到几百米不等），而且语音沟通无法获取现场的图像视频，报警人描述在紧急情况下可能存在失真的情况。为解决上述问题，某消防支队119指挥中心安装云钜科技119互联网辅助接处警系统，该系统通过基站和GPS双重定位，可精确锁定灾情位置，同时通过在线视频电话真实了解灾情现场情况，为出警调度提供支撑。该系统部署以来，每日为该消防救援支队提供上百次警情处置服务，获得了接警工作人员的大力好评。'
  // },
  // {
  //   id: 3,
  //   type: [TYPE_STATE.NEW, TYPE_STATE.FIRE],
  //   title: '灭火救援数字化预案系统公司产品',
  //   avatar: getImg(2),
  //   content:
  //     '某消防救援大队辖区内有很多高危企业（消防重点单位），包括石化储运企业、电力能源企业等，上级要求针对这些重点单位制定相应的灭火救援预案，以便灾情发生时能针对性处置，料敌于先。传统的灭火救援预案都是文本预案，存在不形象、不简洁、实际操作难的问题，实际指导意义不大，也无法基于真实数据环境进行推演。该消防救援大队部署云钜科技灭火救援三维数字化预案系统，为辖区内重点单位构建三维仿真模型，并在模型上标注消防设施位置，录入大队装备、人力资源等数据，形成高度仿真的电子沙盘，队站指挥员与消防员可基于电子沙盘进行重点单位灭火救援过程推演，模拟灾情发生、企业处置、力量调派与力量部署等环节，真正做到战训一体，无缝对接，为辖区重点单位火灾事故处置增加了胜利的筹码。'
  // },
  {
    id: 0,
    type: [TYPE_STATE.NEW, TYPE_STATE.FIRE, 'manage'],
    title: '数据治理与数据资产管理',
    avatar: getImg(0, true),
    imgNum: 4,
    content: '云钜数据遵循阿里数据中台建设路线，为客户提供基于OneData、OneEntity和OneService的数据中台产品解决方案，旨在提供数据采集、数据模型、数据计算和萃取、数据治理、数据资产、数据服务等全链路的一站式产品+技术+方法论服务，构建面向业务应用的数据智能平台。'
  },
  {
    id: 1,
    type: [TYPE_STATE.NEW, TYPE_STATE.FIRE, 'central'],
    title: '企业数据中台',
    avatar: getImg(1, true),
    imgNum: 4,
    content: '某大型房地产某金融研究机构基于云钜中台体系打造行业数据中台，实现了数据的统一治理和数据应用的敏捷开发，快速高效产出行业数据应用，加速数据价值变现的过程'
  },
  {
    id: 2,
    type: [TYPE_STATE.NEW, TYPE_STATE.FIRE, 'control'],
    title: '企业数据中台',
    avatar: getImg(2, true),
    imgNum: 3,
    content: '某大型房地产某金融研究机构基于云钜中台体系打造行业数据中台，实现了数据的统一治理和数据应用的敏捷开发，快速高效产出行业数据应用，加速数据价值变现的过程'
  },
  {
    id: 3,
    type: [TYPE_STATE.NEW, TYPE_STATE.FIRE, 'screen'],
    title: '区域农业大数据',
    avatar: getImg(3, true),
    imgNum: 1,
    content: `农业生产过程的标准化（从定性到定量）。传统农业靠天吃饭，受气候、土壤、疾病等外界因素影响较大，不可控，不标准。
    农业生产过程的数字化（业务数字化），以数字对生产过程进行精确的表达，建立精确的农业生产数字化模型，采集生产过程数据。传统农业靠感觉、靠经验。数字农业一切过程用数字说话，不含糊。
    数据业务化，建立生产过程数据和结果关联模型，通过对过程的精准控制，实现产品质量的标准化。
    场景自动化。在不同的养殖场景，要做什么事情，通过信息系统把这些事情采集、编程，通过设备、系统自动化处理，减少人为干预。人管机器，机器养殖。
    服务智能化。市场需求与企业供给形成互动，逐渐实现从以产定销转变以销定产。通过数字农业的发展，把产供销的信息有机的结合起来，最大限度的减轻价格或者周期的波动。`
  },
  {
    id: 4,
    type: [TYPE_STATE.NEW, TYPE_STATE.FIRE, 'logistics'],
    title: '智慧冷链',
    avatar: getImg(4, true),
    imgNum: 1,
    content: `按照分步实施的原则，大数据智慧平台（以下简称冷链平台）项目拟分期实现总体规划目标。在一期，以“1+3+4”模式建立冷链基础平台，满足现阶段业务需求。建立 1 个指挥中心，3 个数据平台,4 个管理应用，实现全量数据采集智能化、车辆调度合理化、产品溯源可视化、平台运营可持续化。具体目标包括：
    1）互联互通：通过平台的运营和数据沉淀，可以实现冷链体系的互联互通:通过建设冷链信息共享平台子系统链接省内已建、在建、新建各种冷藏库、冷链运输公司、冷链相关从业单位及企业、农产品基地、加工分拣中心、可以打破各种信息孤岛，实现全省冷链资源的信息互通。
    2）食品安全溯源:基于智能设备、物流网、5G 技术,通过对智能设备的传感、传输、解析，实现对预冷、仓储、检测、运输配送、分拣、包装等各环节的信息采集，建立起完整的食品安全溯源体系。
    3）5G 物联智配：通过对各冷链仓库、配送车辆、冷藏柜、分拣、包装等环节的实时监控，对采集的数据经过自动感知模型，优化决策模型处理，实现各种异常情况的预警和矫正优化，再经过优化决策模型，可以降低农产品的损耗率，甚至能预防安全生产事故的发生。
    4）数据共享:平台部署在运营总部，通过对外服务子平台对应急管理厅、农业厅、商务厅、交通运输厅、省大数据中心等单位共享平台数据,是农业大数据的重要来源。
    5）大数据可视化:采集冷链骨干网的仓储库存库容状态、配送运输网络的繁忙程度、各冷链库的仓间调配以及流通环节各节点过程数据，经过采集、清洗、存储、可视、应用等全流程整合，通过技术处理将零散的数据进行“线上化、数字化、可视化、动态化”，使得全省冷链资源可查、可视、可控，从而提升利用效率，从而助推全省农业生产和流通体系提质增效。`
  },
  {
    id: 5,
    type: [TYPE_STATE.NEW, TYPE_STATE.FIRE, 'farmManage'],
    title: '智慧农业',
    avatar: getImg(5, true),
    imgNum: 1,
    content: `农业生产过程的标准化（从定性到定量）。传统农业靠天吃饭，受气候、土壤、疾病等外界因素影响较大，不可控，不标准。
    农业生产过程的数字化（业务数字化），以数字对生产过程进行精确的表达，建立精确的农业生产数字化模型，采集生产过程数据。传统农业靠感觉、靠经验。数字农业一切过程用数字说话，不含糊。
    数据业务化，建立生产过程数据和结果关联模型，通过对过程的精准控制，实现产品质量的标准化。
    场景自动化。在不同的养殖场景，要做什么事情，通过信息系统把这些事情采集、编程，通过设备、系统自动化处理，减少人为干预。人管机器，机器养殖。
    服务智能化。市场需求与企业供给形成互动，逐渐实现从以产定销转变以销定产。通过数字农业的发展，把产供销的信息有机的结合起来，最大限度的减轻价格或者周期的波动。
    `
  },
  {
    id: 6,
    type: [TYPE_STATE.NEW, TYPE_STATE.FIRE, 'factory'],
    title: '数字化工厂',
    avatar: getImg(6, true),
    imgNum: 1,
    content: 'MES能通过信息传递对从订单下达到产品完成的整个生产过程进行优化管理。当工厂发生实时事件时，MES能对此及时做出反应、报告，并用当前的准确数据对它们进行指导和处理。这种对状态变化的迅速响应使MES能够减少企业内部没有附加值的活动，有效地指导工厂的生产运作过程，从而使其既能提高工厂及时交货能力，改善物料的流通性能，又能提高生产回报率。'
  },
]
