<template>
  <div>
    <div class="media-content">
      <media-item v-for="(item, index) in lists" :key="index" :item="item"></media-item>
      <div class="place-item"></div>
      <div class="place-item"></div>
      <div class="place-item"></div>
      <div class="place-item"></div>
    </div>

    <paginate :page-count="pageCount" :click-handler="onClickHandler" prev-text="<" next-text=">" container-class="paginate-class"> </paginate>
  </div>
</template>

<script>
import Paginate from 'vuejs-paginate'
import MediaItem from './media-item'
import MediaData from '@/views/media.js'

const SIZE = 9

export default {
  components: {
    Paginate,
    MediaItem
  },

  data() {
    return {
      lists: [],
      pageCount: 0,
      currentIndex: 0
    }
  },

  created() {
    let lists = []

    let i = 0
    for (let item of MediaData) {
      if (!lists[i]) lists[i] = []
      if (lists[i].length < SIZE) {
        lists[i].push(item)
      } else {
        i++
      }
    }

    this.lists = lists[0]
    this.pageCount = lists.length
    this.allLists = lists
  },

  methods: {
    onClickHandler(index) {
      this.lists = this.allLists[index - 1]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.media-content {
  padding: 70px 50px 0 120px;
  box-sizing: border-box;
  @include flex-center(center);
  flex-wrap: wrap;

  /deep/ .media-content_item {
    margin-right: 26px;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 768px) {
    padding: 30px 10px 0 20px;

    /deep/ .media-content_item {
      margin-right: 10px;
      margin-bottom: 12px;
    }
  }
}

.paginate-class {
  @include flex-center(center);
  margin: 50px 0 40px;
  /deep/ li {
    a {
      display: inline-block;
      @include size(30px);
      font-size: 14px;
      line-height: 30px;
      text-align: center;
      color: rgba($color: #000000, $alpha: 0.8);
      background-color: #f5f5f5;
      margin-right: 12px;

      &:hover {
        color: #fff;
        background-color: #034dbb;
      }

      &:focus {
        border: none;
        outline: none;
      }
    }

    &:focus {
      outline: none;
    }

    &.active a {
      color: #fff;
      background-color: #005eef;
    }
  }
}

.place-item {
  flex: 0 0 384px;
  width: 384px;
  height: 0;
  margin: 0;
}
</style>
