<template>
  <div class="footer">
    <div class="footer-container">
      <ul>
        <li>
          <dl>
            <dt>
              <img src="~@/assets/images/zkyt.png" alt="云钜科技" />
            </dt>
            <dd>
              <p>广东省广州市黄埔区 光谱中路11号 D 栋 204</p>
            </dd>
            <dd><span>使用条款</span> | <span>法律声明</span> | <span>隐私政策</span></dd>
          </dl>
        </li>
        <li>
          <dl>
            <dt>产品链接</dt>
            <dd v-for="item in productLists" :key="item.key" @click="checkProduct(item.key)">{{ item.name }}</dd>
          </dl>
        </li>
        <li>
          <dl>
            <dt>公司</dt>
            <dd @click="$router.replace({ name: 'Home' })">首页</dd>
            <dd v-for="item in defaultLists" :key="item.key" @click="$router.push({ name: item.key })">{{ item.name }}</dd>
          </dl>
        </li>
        <li>
          <dl>
            <dt>联系我们</dt>
            <dd>邮箱：admin@gdyunju.com</dd>
            <dd>公司电话：020-31801094</dd>
            <dd>商务合作：020-31801094</dd>
          </dl>
        </li>
      </ul>
      <div class="file">
        <p>
          Copyright © 2024 yjdongcha.com. All Rights Reserved. 广东云钜网络科技有限公司 版权所有 |
          <a target="_blank" rel="external nofollow noopener" href="https://beian.miit.gov.cn">粤ICP备2023128403号</a>
        </p>
        <img src="~@/assets/images/weChat.png" alt="微信联系" />
      </div>
    </div>

    <div class="footer-container_2">
      <img src="~@/assets/images/zkyt.png" alt="云钜科技" />

      <ul>
        <li>
          <dl>
            <dt>联系我们</dt>
            <dd>邮箱：admin@gdyunju.com</dd>
            <dd>公司电话：020-31801094</dd>
            <dd>商务合作：020-31801094</dd>
          </dl>
        </li>

        <li>
          <dl>
            <dt>产品链接</dt>
            <dd v-for="item in productLists" :key="item.key" @click="$router.push({ name: item.key })">{{ item.name }}</dd>
          </dl>
        </li>

        <li>
          <dl>
            <dt>公司</dt>
            <dd @click="$router.replace({ name: 'Home' })">首页</dd>
            <dd v-for="item in defaultLists" :key="item.key" @click="$router.push({ name: item.key })">{{ item.name }}</dd>
          </dl>
        </li>
      </ul>

      <div>
        <img src="~@/assets/images/weChat.png" alt="微信联系" />
        <p>地址:广州市开发区彩频路 7号广东软件科学园 D 栋 3 楼</p>
        <p>
          <span>使用条款</span> | <span>法律声明</span> |
          <span>隐私政策</span>
        </p>

        <p>
          Copyright © 2019 - 2021 zkyunteng. All Rights Reserved. 云钜科技科技有限公司 版权所有 |
          <a target="_blank" rel="external nofollow noopener" href="http://www.beian.miit.gov.cn">粤ICP备20034522号-3</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      productLists: [
        {
          name: '数据治理与数据资产管理',
          key: 'manage'
        },
        {
          name: '企业数据中台',
          key: 'central'
        },
        {
          name: '数据运营监控',
          key: 'control'
        },
        {
          name: '区域农业大数据',
          key: 'screen'
        },
        {
          name: '智慧冷链',
          key: 'logistics'
        },
        {
          name: '智慧农业',
          key: 'farmManage'
        },
        {
          name: '数字化工厂',
          key: 'factory'
        }
      ],

      defaultLists: [
        {
          name: '公司产品',
          key: 'Talentplan'
        },
        {
          name: '媒体中心',
          key: 'Media'
        },
        {
          name: '关于我们',
          key: 'About'
        }
      ]
    }
  },

  methods: {
    checkProduct(name) {
      this.$router.push({
        name: 'Talentplan',
        query: { name }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.footer {
  width: 100%;
  padding: 144px 158px 0;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  box-sizing: border-box;
  &-container {
    width: 100%;
    .file {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      padding: 32px 0;
      box-sizing: border-box;
      img {
        width: 24px;
      }
    }
    ul {
      width: 100%;
      display: flex;
      border-bottom: 1px solid #d8d8d8;
      justify-content: space-between;
      li {
        &:first-child {
          width: 360px;
          margin-right: 20px;
        }
        &:nth-child(2) {
          width: 309px;
        }
        &:nth-child(3) {
          width: 96px;
        }
        &:nth-child(4) {
          width: 250px;
        }
        dl {
          dt {
            width: 100%;
            margin-bottom: 43px;
            font-size: 18px;
            font-weight: 500;
            color: #333333;
            text-align: left;
            img {
              width: 52.9%;
              text-align: left;
            }
          }
          dd {
            text-align: left;
            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.6);
            margin-bottom: 32px;
            cursor: pointer;
            &:hover {
              color: #0e57a2;
            }
          }
        }
      }
    }
  }

  &-container_2 {
    @include flex-center(center, column);
    align-items: flex-start;
    color: rgba($color: #000000, $alpha: 0.6);
    font-size: 12px;

    display: none;

    & > img {
      @include size(106.5px, 30.5px);
    }

    & > ul {
      width: 100%;
      @include flex-center(flex-start);
      flex-wrap: wrap;
      align-items: flex-start;
      padding-bottom: 25px;
      border-bottom: 1px solid #d8d8d8;

      li {
        margin-top: 35px;
        &:nth-child(1) {
          flex-basis: 100%;
        }
        &:nth-child(2) {
          flex-basis: 70%;
        }
        &:nth-child(3) {
          flex-shrink: 0;
        }

        dt {
          color: #333;
          font-size: 14px;
        }
        dd {
          margin-top: 20px;
        }
      }
    }

    & > div {
      line-height: 1.5;
      @include flex-center(center, column);

      img {
        @include size(20px);
        margin: 8px 0;
        align-self: flex-start;
      }

      p:last-of-type {
        margin: 16px 0 33px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 25px;
    margin-top: 15px;

    &-container {
      display: none;
    }

    &-container_2 {
      display: flex;
    }
  }
}
</style>
