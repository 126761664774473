<template>
  <div class="media-detail">
    <div class="media-detail_route">
      <!-- eslint-disable -->
      <span @click="goBack"> < &nbsp;</span>
      <!-- eslint-enable -->
      <span @click="goBack">媒体中心</span>
    </div>

    <div class="media-detail_main">
      <!-- 正文 -->
      <div class="media-detail_main_content">
        <h2>{{ mediaData.title }}</h2>

        <p>
          <span>{{ mediaData.time }}</span>
          <svg t="1612249810455" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3994" width="14" height="14">
            <path
              d="M370.085366 0c76.573794 0 121.827756 41.41888 216.426433 135.122705l38.734323 37.839471 382.357626 390.539134A51.13442 51.13442 0 0 1 1022.688402 601.085108a49.85606 49.85606 0 0 1-13.678457 36.049767L626.780154 1009.904797a55.480846 55.480846 0 0 1-38.606487 14.189802 54.969502 54.969502 0 0 1-38.606487-14.061966L74.656253 544.07023C2.045377 473.632566 0 464.939715 0 391.178314V167.465226C1.278361 23.777505 18.791899 0 178.586962 0z m-111.217364 172.578668a86.545006 86.545006 0 0 0-86.161498 86.41717c0 47.427175 38.734323 86.289334 86.289334 86.289334a86.289334 86.289334 0 1 0 0-172.578668z"
              fill="#005EEF"
              p-id="3995"
            ></path>
          </svg>
          <span>来源：{{ mediaData.from || '云钜科技科技' }}</span>
        </p>

        <div class="media-detail_content">
          <img :src="mediaData.logo" :alt="mediaData.title" />
          <!-- eslint-disable -->
          <template v-for="item in mediaData.data">
            <p
              v-if="item.type == 1"
              :class="{
                indent: !item.noIndent
              }"
            >
              {{ item.content }}
            </p>
            <img v-else-if="item.type == 2" :src="item.content" alt="云钜科技" />
          </template>
          <!-- eslint-enable -->
        </div>
      </div>
      <!-- 右侧导航 -->
      <div class="media-detail_right">
        <h3>热门新闻</h3>
        <div>
          <media-item class="media-item" v-for="(item, index) in randomData" :key="index" :item="item"></media-item>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MediaItem from './media-item'
import MediaData from '@/views/media.js'

export default {
  components: {
    MediaItem
  },

  data() {
    return {
      mediaData: {},

      randomData: []
    }
  },

  created() {
    this.mediaData = MediaData.find(item => +item.id === +this.$route.params.id)
    this.getRecentMedias()
  },

  methods: {
    getRecentMedias() {
      this.randomData = MediaData.filter(i => +i.id !== +this.mediaData.id)
    },

    goBack() {
      this.$router.replace({ path: '/media' })
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/styles/mixins';

.media-detail_route {
  width: 100%;
  @include flex-center(flex-start);
  padding: 23px 120px;
  background-color: #fff;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
  box-sizing: border-box;
  span {
    margin-right: 10px;
    cursor: pointer;
    &:first-child {
      color: #005eef;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 12px 20px;
  }
}

.media-detail_main {
  @include size(100%);
  @include flex-center(space-between);
  align-items: flex-start;
  padding: 0 119px;
  background-color: #fff;
  color: rgba($color: #000000, $alpha: 0.8);
  box-sizing: border-box;

  .media-detail_main_content {
    font-size: 16px;
    flex-grow: 2;
    margin-right: 50px;

    h2 {
      font-size: 32px;
      margin-top: 26px;
    }

    & > p {
      @include flex-center(flex-start);
      margin-top: 16px;
      padding-bottom: 21px;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
      span {
        display: inline-block;
      }
      span:first-child {
        margin-right: 61px;
      }
      span:last-child {
        margin-left: 5px;
        font-size: 12px;
        color: rgba($color: #000000, $alpha: 0.5);
      }
    }

    .media-detail_content {
      margin-top: 23px;

      img {
        width: 100%;
        height: auto;
        margin-bottom: 19px;
      }

      p {
        color: #666;
        margin-bottom: 30px;
        line-height: 1.5;
        &.indent {
          text-indent: 2em;
        }
      }
    }
  }

  .media-detail_right {
    @include flex-center(center, column);
    flex-shrink: 0;
    h3 {
      width: 100%;
      font-size: 24px;
      margin-top: 66px;
      padding-bottom: 24px;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
    }

    & > div {
      @include flex-center(center, column);
      flex-wrap: wrap;

      .media-item {
        margin-top: 30px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0 20px;
    flex-direction: row;
    flex-wrap: wrap;

    .media-detail_main_content {
      font-size: 12px;
      margin-right: 0;
      h2 {
        font-size: 14px;
        margin-top: 16px;
      }

      & > p {
        border-bottom: none;
      }

      .media-detail_content {
        margin-top: 0;
      }
    }

    .media-detail_right {
      h3 {
        font-size: 20px;
        margin-top: 40px;
        text-align: center;
        padding-bottom: 0;
        border-bottom: none;
      }

      & > div {
        // @include flex-center(center);
        .media-item {
          margin-top: 16px;
          // margin-right: 12px;
        }
      }

      /deep/ .media-content_item {
        width: 335px;
      }
    }
  }
}
</style>
