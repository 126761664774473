<template>
  <div class="media-container">
    <banner :center="true" title="媒体中心" content="了解云钜科技新闻，掌握企业服务前沿资讯" background="https://socialize.zkyunteng.cn/fronts/website/media-banner.jpg"></banner>

    <transition name="page" mode="out-in" enter-active-class="animate__animated animate__fadeIn">
      <router-view />
    </transition>
  </div>
</template>

<script>
import Banner from '@/components/Banner'

export default {
  name: 'Media',

  components: {
    Banner
  }
}
</script>
