<template>
  <div class="media-content_item" @click="viewDetail">
    <div
      :style="{
        backgroundImage: 'url(' + item.logo + ')'
      }"
    ></div>
    <div>
      <p>{{ item.title }}</p>
      <p>
        <svg t="1612249810455" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3994" width="16" height="16">
          <path
            d="M370.085366 0c76.573794 0 121.827756 41.41888 216.426433 135.122705l38.734323 37.839471 382.357626 390.539134A51.13442 51.13442 0 0 1 1022.688402 601.085108a49.85606 49.85606 0 0 1-13.678457 36.049767L626.780154 1009.904797a55.480846 55.480846 0 0 1-38.606487 14.189802 54.969502 54.969502 0 0 1-38.606487-14.061966L74.656253 544.07023C2.045377 473.632566 0 464.939715 0 391.178314V167.465226C1.278361 23.777505 18.791899 0 178.586962 0z m-111.217364 172.578668a86.545006 86.545006 0 0 0-86.161498 86.41717c0 47.427175 38.734323 86.289334 86.289334 86.289334a86.289334 86.289334 0 1 0 0-172.578668z"
            fill="#005EEF"
            p-id="3995"
          ></path>
        </svg>
        <span>来源：{{ item.from || '云钜科技科技' }}</span>
        <span>{{ item.time }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item'],
  methods: {
    viewDetail() {
      this.$router.push({ path: '/media/' + this.item.id })
      this.$router.go(0)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.media-content_item {
  @include size(384px, 100%);
  box-shadow: 2px 2px 8px 0px rgba(192, 192, 192, 0.4);
  background-color: #fff;
  transition: transform 0.2s ease;
  overflow: hidden;
  transform: scale(1);
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }

  & > div:first-child {
    @include size(100%, 163px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    transform: scale(1);
    transition: transform 0.2s ease;

    &:hover {
      transform: scale(1.03);
    }
  }

  & > div:last-child {
    padding: 15px 24px 24px;
    box-sizing: border-box;
    height: 100%;

    p:first-child {
      font-size: 14px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    p:last-child {
      font-size: 12px;
      color: rgba($color: #000000, $alpha: 0.5);
      margin-top: 24px;
      @include flex-center();
      svg {
        margin-right: 10px;
      }

      span:last-child {
        margin-left: auto;
      }
    }
  }
}
</style>
