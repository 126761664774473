import home from '@/assets/images/home.png'
import world from '@/assets/images/world.png'
import fire from '@/assets/images/fire.png'

export default [
  {
    label: '首页',
    key: 'home'
  },
  {
    label: '产品导航',
    key: 'products',
    children: [
      {
        title: '大数据产业',
        icon: home,
        subTitles: [
          { title: '数据治理与数据资产管理', name: 'manage' },
          { title: '企业数据中台', name: 'central' },
          { title: '数据运营监控', name: 'control' }
        ]
      },
      {
        title: '乡村振兴',
        icon: world,
        subTitles: [
          { title: '区域农业大数据', name: 'screen' },
          { title: '智慧冷链', name: 'logistics' },
          { title: '智慧农业', name: 'farmManage' }
          // {
          //   title: '灭火救援数字化预案管理平台',
          //   name: 'Fireplan'
          // },
          // { title: '119互联网辅助接处警系统', name: 'Alarm' }
        ]
      },
      {
        title: '工业制造',
        icon: fire,
        subTitles: [
          // {
          //   title: '数字化工厂',
          //   name: 'Society'
          // },
          {
            title: '数字化工厂',
            name: 'Monitor'
          }
        ]
      }
    ],
    children2: [
      { title: '城市应急安全数据中台', name: 'Safe' },
      { title: '装备实战管理平台', name: 'Device' },
      { title: '灭火救援数字化预案管理平台', name: 'Fireplan' },
      { title: '119互联网辅助接处警系统', name: 'Alarm' },
      { title: '社会单位数字化应急预案管理平台', name: 'Society' },
      { title: '社会单位智慧消防物联网监控云平台', name: 'Monitor' }
    ]
  },
  {
    label: '媒体中心',
    key: 'media'
  },
  // {
  //   label: '公司产品',
  //   key: 'talentplan'
  // },
  {
    label: '关于我们',
    key: 'about'
  }
]
