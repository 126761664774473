import Vue from 'vue'
import VueRouter from 'vue-router'

//首页
import Home from '../views/Home'

// 产品与服务
import Products from '@/views/Products'
import AlarmSystem from '@/views/Products/AlarmSystem'
import DeviceManage from '@/views/Products/DeviceManage'
import EmergencySafety from '@/views/Products/EmergencySafety'
import FirePlanSystem from '@/views/Products/FirePlanSystem'
import SocietyPlan from '@/views/Products/SocietyPlan'
import MonitorControl from '@/views/Products/MonitorControl'

// 媒体中心
import Media from '../views/Media/index'
import MediaList from '../views/Media/lists'
import MediaDetail from '../views/Media/detail'

// 人才中心
import TalentPlan from '../views/TalentPlan/index'
import TalentPlanList from '../views/TalentPlan/lists'
import TalentPlanDetail from '../views/TalentPlan/detail'

// 关于我们
import About from '../views/About'

import NotFound from '@/views/404'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '首页'
    }
  },
  {
    path: '/products',
    name: 'Products',
    component: Products,
    meta: {
      title: '产品与服务'
    },
    children: [
      {
        path: 'alarm',
        name: 'Alarm',
        component: AlarmSystem,
        meta: {
          title: '119互联网辅助接处警系统'
        }
      },
      {
        path: '/products/device',
        name: 'Device',
        component: DeviceManage,
        meta: {
          title: '装备实战管理平台'
        }
      },
      {
        path: '/products/safe',
        name: 'Safe',
        component: EmergencySafety,
        meta: {
          title: '城市应急安全数据中台'
        }
      },
      {
        path: '/products/fireplan',
        name: 'Fireplan',
        component: FirePlanSystem,
        meta: {
          title: '灭火救援数字化预案管理平台'
        }
      },
      {
        path: '/products/society',
        name: 'Society',
        component: SocietyPlan,
        meta: {
          title: '社会单位数字化应急预案管理平台'
        }
      },
      {
        path: '/products/monitor',
        name: 'Monitor',
        component: MonitorControl,
        meta: {
          title: '社会单位智慧消防物联网监控云平台'
        }
      }
    ]
  },
  {
    path: '/media',
    name: 'Media',
    component: Media,
    redirect: '/media',
    meta: {
      title: '媒体中心'
    },
    children: [
      {
        path: '/media',
        component: MediaList
      },
      {
        path: '/media/:id',
        component: MediaDetail
      }
    ]
  },
  {
    path: '/talentPlan',
    name: 'Talentplan',
    component: TalentPlan,
    redirect: '/talentPlan',
    meta: {
      title: '公司产品'
    },
    children: [
      {
        path: '/talentPlan',
        component: TalentPlanList
      },
      {
        path: '/talentPlan/:id',
        component: TalentPlanDetail
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      title: '关于我们'
    }
  },
  {
    path: '*',
    component: NotFound
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

Vue.use(VueRouter)

router.afterEach(() => {
  document.scrollingElement.scrollTop = 0
})

export default router
